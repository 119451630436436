export const useConfig = () => {
  const storyblokStore = useStoryblokStore();
  const config = storyblokStore.runtimeConfig;

  const storyblokApiOptions = useStoryblokApiOptions();
  const storyblokApi = useStoryblokApi();
  const websiteConfig = useState("config");

  // function sleep(ms) {
  //   return new Promise((resolve) => setTimeout(resolve, ms));
  // }

  const { locale } = useI18n();
  // await sleep(1);

  const { data } = websiteConfig.value;

  const configData = {
    ...data.story.content,

    storyblokApiOptions: {
      ...config.public.storyblok.apiOptions,
      language: locale.value,
    },

    moreLink: {
      text: data.story.content.more_link_text,
    },
    moreAboutLink: {
      text: data.story.content.more_about_link_text,
    },

    analyticsBanner: data.story.content.analytics_banner_config[0],
    contactCta: data.story.content.contact_cta[0],

    consultingStep: {
      keyvisual_pre_headline:
        data.story.content.consulting_step_keyvisual_pre_headline,
      whatsImportant: {
        pre_headline: data.story.content.whats_important_pre_headline,
        headline: data.story.content.whats_important_headline,
      },
      details: {
        pre_headline: data.story.content.details_pre_headline,
        headline: data.story.content.details_headline,
      },
    },

    glossar: {
      definition: {
        headline: data.story.content.glossar_definition_headline,
      },
      origin: {
        headline: data.story.content.glossar_origin_headline,
      },
      synonyms: {
        headline: data.story.content.glossar_synonyms_headline,
      },
    },

    useCase: {
      link_text: data.story.content.use_case_link_text,
    },
  };

  const fetchConfig = async () => {
    const { data } = await storyblokApi.get("cdn/stories/config", {
      ...storyblokApiOptions,
      language: locale.value,
    });
    storyblokStore.config = { data };
    websiteConfig.value = { data };
  };

  return {
    fetchConfig,
    ...configData,
    data,
  };
};
